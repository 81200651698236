<template>
    <v-container grid-list text-center>
        <v-form v-model="valid" ref="form" lazy-validation @submit.prevent="makeCheckin">
            <v-layout wrap>
                <v-flex xs12 class="ma-2" v-if="canNextStep">
                    <p class="font-weight-black">Preencha as informações abaixo:</p>
                </v-flex>

                <v-flex xs12>
                    <v-card dark color="error" v-if="batteries == null">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title class="headline">Buscando baterias...</v-card-title>
                            </div>
                            <v-avatar class="ma-3" size="50" tile>
                                <v-progress-circular size="50" width="10" color="white" indeterminate/>
                            </v-avatar>
                        </div>
                    </v-card>

                    <v-alert dense outlined type="error" v-else-if="batteries.length == 0"> Nenhuma bateria disponível para uso. </v-alert>

                    <v-layout wrap v-else>
                        <v-flex xs8>
                            <v-autocomplete v-model="item.batteries[0].battery" :items="batteriesFilter(1)" :filter="customFilterBattery" item-value="id" :label="$t('Bateria')"
                                :rules="[rules.required]">
                                <template v-slot:item="data">
                                    <div v-if="data.item.tag">Número: {{ data.item.tag }}</div>
                                    <div v-else-if="data.item.name">Código: {{ data.item.name }}</div>
                                </template>

                                <template v-slot:selection="data">
                                    <div v-if="data.item.tag">Número: {{ data.item.tag }}</div>
                                    <div v-else-if="data.item.name">Código: {{ data.item.name }}</div>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                        <v-flex xs3 offset-xs1>
                            <v-text-field type="number" :label="$t('Porcentagem Bateria')" suffix="%" v-model="item.batteries[0].percentStart"
                                :rules="[rules.required, rules.range(0, 100)]" />
                        </v-flex>

                        <v-flex xs8 v-if="twoBattery">
                            <v-autocomplete v-model="item.batteries[1].battery" :items="batteriesFilter(0)" :filter="customFilterBattery" item-value="id"
                                :label="$t('Bateria Extra')" :rules="[rules.required]">

                                <template v-slot:item="data">
                                    <div v-if="data.item.tag">Número: {{ data.item.tag }}</div>
                                    <div v-else-if="data.item.name">Código: {{ data.item.name }}</div>
                                </template>

                                <template v-slot:selection="data">
                                    <div v-if="data.item.tag">Número: {{ data.item.tag }}</div>
                                    <div v-else-if="data.item.name">Código: {{ data.item.name }}</div>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                        <v-flex xs3 offset-xs1 v-if="twoBattery">
                            <v-text-field type="number" :label="$t('Porcentagem Bateria Extra')" suffix="%" v-model="item.batteries[1].percentStart"
                                :rules="[percentTwoRequired, rules.range(0, 100)]" />
                        </v-flex>

                        <v-flex xs12 v-if="!twoBattery">
                            <v-btn color="error" @click="twoBattery = true" x-small>Adicionar bateria extra</v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>

                <v-flex xs12>
                    <v-card dark color="error" v-if="modals == null">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title class="headline">Buscando veículo...</v-card-title>
                            </div>
                            <v-avatar class="ma-3" size="50" tile>
                                <v-progress-circular size="50" width="10" color="white" indeterminate/>
                            </v-avatar>
                        </div>
                    </v-card>

                    <v-alert dense outlined type="error" v-else-if="modals.length == 0"> Nenhuma veículo disponível para uso. </v-alert>

                    <div v-else>
                        <v-autocomplete v-model="item.modals.modal" :items="modals" :filter="customFilterModal" item-value="id" :label="$t('Veículo')" :rules="[rules.required]">

                            <template v-slot:item="data">
                                <div v-if="data.item.plate">Placa: {{ data.item.plate }}</div>
                                <div v-if="data.item.serialNumber">Número de Série: {{ data.item.serialNumber }}</div>
                            </template>

                            <template v-slot:selection="data">
                                <div v-if="data.item.plate">Placa: {{ data.item.plate }}</div>
                                <div v-if="data.item.serialNumber">Número de Série: {{ data.item.serialNumber }}</div>
                            </template>
                        </v-autocomplete>
                    </div>
                </v-flex>

                <v-flex xs12 class="ma-2" v-if="canNextStep">
                    <v-text-field v-model="item.modals.odometerIn" :label="$t('Digite o valor do km atual do painel')" type="number" suffix="km"
                        :rules="[rules.required, rules.odometer]" />
                </v-flex>

                <v-flex xs12 class="ma-2" v-if="canNextStep">
                    <v-btn @click="makeCheckin" :disabled="!valid" color="green white--text" style="width: 100%"> <v-icon left> play_arrow </v-icon> Próximo passo </v-btn>
                </v-flex>
                <v-flex xs12 class="ma-2" v-else>
                    <v-btn @click="$emit('onCancel')" color="error white--text" style="width: 100%"> <v-icon left> error </v-icon> Voltar </v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
import rules from "@/helpers/rules";
export default {
    props: {
        store: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            valid: true,
            rules,
            item: {
                odometerIn: null,
                modals: { modal: null, odometerIn: null },
                batteries: [
                    {
                        battery: null,
                        percentStart: null,
                    },
                    {
                        battery: null,
                        percentStart: null,
                    },
                ],
            },
            modals: null,
            batteries: null,
            twoBattery: false,
            percentTwoRequired: (value) => this.item.batteries[1].battery == null || value != null || this.$t("Obrigatório."),
        };
    },

    computed: {
        canNextStep() {
            return this.modals != null && this.batteries != null && this.modals.length > 0 && this.batteries.length > 0;
        },
        batteriesFilter() {
            return (index) => this.batteries.filter((e) => e.id != this.item.batteries[index].battery);
        },
    },

    methods: {
        customFilterModal(item, queryText) {
            const searchText = queryText.toLowerCase();
            return (item.plate && item.plate.toLowerCase().indexOf(searchText) > -1) || (item.serialNumber && item.serialNumber.toLowerCase().indexOf(searchText) > -1);
        },
        customFilterBattery(element, queryText) {
            const searchText = queryText.toLowerCase();
            return (element.name && element.name.toLowerCase().indexOf(searchText) > -1) || (element.tag && element.tag.toLowerCase().indexOf(searchText) > -1);
        },
        makeCheckin() {
            if (this.$refs.form.validate()) {
                this.$emit("onSelect", this.item);
            }
        },
        getModals() {
            this.$http
                .get(`modals/byStore/${this.store}`)
                .then((result) => {
                    if (result) {
                        this.modals = result;
                    }
                })
                .catch(() => {
                    this.modals = null;
                });
        },
        getBatteries() {
            this.$http
                .get(`batteries/byStore/${this.store}`)
                .then((result) => {
                    if (result) {
                        this.batteries = result;
                    }
                })
                .catch(() => {
                    this.batteries = null;
                });
        },
    },

    mounted() {
        this.getModals();
        this.getBatteries();
    },
};
</script>
