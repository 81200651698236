import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import 'vuetify/src/styles/main.sass'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import dictionary from '@/plugins/i18n'
import store from '@/helpers/store'

Vue.use(Vuetify)
Vue.use(VueI18n)

const i18n = new VueI18n(dictionary)

export default new Vuetify({
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    icons: {
        iconfont: 'md' || 'fa'
    },
    theme: {
        options: {
            customProperties: true,
        },
        dark: store.getters.isDarkMode,
        themes: {
            light: {
                primary: '#fe9034',
                secondary: '#145e65',
                accent: '#0b98a2',
                error: '#FF5A60',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
            dark: {
                primary: '#fe9034',
                secondary: '#145e65',
                accent: '#0b98a2',
                error: '#FF5A60',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            }
        }
    }
})