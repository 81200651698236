<template>
    <v-container grid-list text-center>
        <v-card dark color="error" v-if="batteries == null">
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="headline">Buscando baterias...</v-card-title>
                </div>
                <v-avatar class="ma-3" size="50" tile>
                    <v-progress-circular size="50" width="10" color="white" indeterminate/>
                </v-avatar>
            </div>
        </v-card>
        <v-card dark color="error" v-else-if="batteries.length == 0">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>Nenhuma bateria disponível para uso.</v-card-title>
            </div>
        </v-card>
        <v-layout wrap v-else-if="itemToChange.batteryOut == null">
            <v-flex xs12 v-for="battery in batteryToFinish" :key="battery">
                <v-alert icon="battery_full" text outlined type="error" v-if="!battery.percentStop">
                    <strong>Bateria: {{ battery.battery.tag || battery.battery.name }}</strong>
                    <v-btn @click="startChange(battery)" :disabled="!valid" color="success white--text" class="mt-4" style="width: 100%">
                        <v-icon left> published_with_changes </v-icon> Confirma a troca dessa bateria?
                    </v-btn>
                </v-alert>
            </v-flex>
            <v-flex xs12 v-if="batteryToFinish.length == 0">
                <v-alert icon="electric_moped" text outlined type="error">
                    <strong>Nenhuma bateria para ser trocada</strong>
                </v-alert>
            </v-flex>
        </v-layout>

        <v-form v-else v-model="valid" ref="form" lazy-validation @submit.prevent="makeCheckin">
            <v-alert outlined type="warning" icon="stop" text>
                <div class="text-h6">Devolução</div>

                <v-flex xs12 class="ma-2">
                    <strong> Bateria: {{ itemToChange.batteryOut.battery.tag || itemToChange.batteryOut.battery.name }} </strong>
                </v-flex>
                <v-flex xs12 class="ma-2">
                    <v-text-field
                        v-model="itemToChange.batteryOut.percentStop"
                        :label="$t('Porcentagem Bateria')"
                        type="number"
                        :rules="[rules.required, maxPercentIn(itemToChange.batteryOut.percentStart), rules.range(0, 100)]"
                    >
                    </v-text-field>
                </v-flex>
            </v-alert>
            <v-alert outlined type="success" icon="play_arrow" text>
                <div class="text-h6">Seleção de nova</div>
                <v-layout wrap>
                    <v-flex xs12>
                        <v-autocomplete
                            v-model="itemToChange.batteryIn.id"
                            :items="batteries"
                            :filter="customFilterBattery"
                            item-value="id"
                            :label="$t('Baterias')"
                            :rules="[rules.required]"
                        >
                            <template v-slot:item="data">
                                <div v-if="data.item.tag">Número: {{ data.item.tag }}</div>
                                <div v-else-if="data.item.name">Código: {{ data.item.name }}</div>
                            </template>
                            <template v-slot:selection="data">
                                <div v-if="data.item.tag">Número: {{ data.item.tag }}</div>
                                <div v-else-if="data.item.name">Código: {{ data.item.name }}</div>
                            </template>
                        </v-autocomplete>
                    </v-flex>

                    <v-flex xs12 class="ma-2">
                        <v-text-field
                            v-model="itemToChange.batteryIn.percentStart"
                            :label="$t('Porcentagem Bateria')"
                            type="number"
                            :rules="[rules.required, rules.range(0, 100)]"
                        />
                    </v-flex>
                </v-layout>
            </v-alert>

            <v-layout wrap>
                <v-flex xs6 class="pr-1">
                    <v-btn @click="makeChange" :disabled="!valid" color="green white--text" style="width: 100%">
                        <v-icon left> published_with_changes </v-icon> Finalizar troca
                    </v-btn>
                </v-flex>
                <v-flex xs6 class="pl-1">
                    <v-btn @click="$emit('onClose')" color="red white--text" style="width: 100%"> <v-icon left> cancel </v-icon> Cancelar </v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
import rules from "@/helpers/rules";
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            valid: true,
            rules,
            batteries: null,
            itemToChange: {
                batteryOut: null,
                batteryIn: { id: null, percentStart: null },
            },
            maxPercentIn: (percentStart) => (value) => value <= percentStart || this.$t("Porcentagem de saída tem que ser menor que a entrada."),
        };
    },

    computed: {
        batteryToFinish() {
            return this.item.batteries.filter((e) => e.percentStop == null);
        },
    },

    methods: {
        startChange(battery) {
            this.itemToChange.batteryOut = battery;
        },
        customFilterBattery(item, queryText) {
            const searchText = queryText.toLowerCase();
            return (item.plate && item.plate.toLowerCase().indexOf(searchText) > -1) || (item.serialNumber && item.serialNumber.toLowerCase().indexOf(searchText) > -1);
        },
        makeChange() {
            if (this.$refs.form.validate()) {
                this.$http
                    .patch(`checkin/${this.item.id}/changeBattery`, {
                        data: this.itemToChange,
                    })
                    .then(() => {
                        this.$emit("onClose");
                    })
                    .catch((error) => this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao fazer troca de bateria.")));
            }
        },
        getBatteries() {
            this.$http
                .get(`batteries/byStore/${this.item.store.id}`)
                .then((result) => {
                    if (result) {
                        this.batteries = result;
                    }
                })
                .catch(() => {
                    this.batteries = null;
                });
        },
    },

    mounted() {
        this.getBatteries();
    },
};
</script>
