<template>
    <v-alert border="left" :color="color" outlined elevation="2">
        <div class="text-h6">
            Chave PIX
        </div>
        <v-btn-toggle v-model="item.pixType" @change="change" mandatory dense>
            <v-btn value="cpf" small>
                CPF
            </v-btn>
            <v-btn value="email" small>
                Email
            </v-btn>
            <v-btn value="phone" small>
                Telefone
            </v-btn>
            <v-btn value="key" small>
                CHAVE
            </v-btn>
        </v-btn-toggle>
        <v-text-field v-if="item.pixType == 'cpf'" inputmode="numeric" v-model="item.pixKey" :rules="[rules.required]" label="CPF" v-mask="'###.###.###-##'" />

        <v-text-field v-if="item.pixType == 'phone'" type="tel" v-model="item.pixKey" :rules="[rules.required, rules.cellphone]" :label="$t('Celular')"
            v-mask="['(##) ####-####', '(##) #####-####']" required />

        <v-text-field v-if="item.pixType == 'email'" type="email" v-model="item.pixKey" :rules="[rules.required, rules.email]" :label="$t('E-mail')" required />

        <v-text-field v-if="item.pixType == 'key'" v-model="item.pixKey" :rules="[rules.required]" :label="$t('Chave')" required />

        <v-autocomplete v-model="item.pixBank" :filter="filterObject" :items="banks" item-text="name" item-value="name" :label="$t('Banco')"/>
    </v-alert>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import banks from "@/assets/json/banks.json";

export default {
    directives: { mask },

    props: {
        item: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            default: "grey",
        },
        phone: {
            type: String,
            default: "",
        },
        email: {
            type: String,
            default: "",
        },
        cpf: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            rules,
            banks,
        };
    },

    methods: {
        change(newValue) {
            this.$nextTick(() => {
                this.item.pixKey = "";
                if (newValue == 'email') {
                    this.item.pixKey = this.email;
                } else if (newValue == 'phone') {
                    this.item.pixKey = this.phone;
                } else if (newValue == 'cpf') {
                    this.item.pixKey = this.cpf;
                }
            });
        },
        filterObject(item, queryText, itemText) {
            itemText = itemText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
            queryText = queryText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();

            return itemText.includes(queryText);
        },
    },
};
</script>