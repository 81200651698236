<template>
    <div>
        <v-alert outlined type="error" prominent border="left" class="ma-4" v-if="locationError">
            Não foi possível usar a sua geolocalização. <br /><br />
            Favor verificar se o seu celular está com esse recurso habilitado.
        </v-alert>
        <strong class="d-flex flex-no-wrap justify-center">{{ $moment(item.createdAt).format("DD/MM/YY HH:mm UTC(Z)") }}</strong>
        <v-timeline align-top dense>
            <v-timeline-item :color="`${getColor(1)} white--text`">
                <v-divider />
                <template v-slot:icon>
                    <span>1</span>
                </template>
                <v-row class="pt-1">
                    <v-col>
                        <strong>{{ item.store.name }}</strong>
                        <div class="text-caption">Você fez checkin nessa loja.</div>
                    </v-col>
                </v-row>
            </v-timeline-item>

            <v-timeline-item :color="`${getColor(2)} white--text`">
                <v-divider />

                <template v-slot:icon>
                    <span>2</span>
                </template>
                <v-row class="pt-1">
                    <v-col v-if="item.deliveryMode == 'OwnMotorcycle' || item.deliveryMode == 'OwnVehicle'">
                        <strong v-if="item.deliveryMode == 'OwnVehicle'">Trabalhando de veículo próprio</strong>
                        <strong v-else>Trabalhando de moto própria</strong>

                        <div class="text-caption" v-for="i in item.modals" :key="i">
                            <div v-if="i.odometerIn">Km na entrada: {{ i.odometerIn }}</div>
                        </div>
                    </v-col>
                    <v-col v-else-if="item.deliveryMode == 'StoreMotorcycle' || item.deliveryMode == 'StoreVehicle'">
                        <strong v-if="item.deliveryMode == 'StoreVehicle'">Trabalhando com veículo empresa</strong>
                        <strong v-lse>Trabalhando com scooter elétrica</strong>

                        <div class=" text-caption" v-for="i in item.modals" :key="i">
                            <div class="d-inline">
                                <strong v-if="i.modal && (i.modal.plate || i.modal.serialNumber)">
                                    {{ item.deliveryMode == 'StoreVehicle' ? "Veículo" : "Scooter" }} {{ i.modal.plate || i.modal.serialNumber }}:
                                </strong>
                            </div>
                            <div class="d-inline" v-if="i.odometerIn != null">({{ i.odometerIn }} Km)</div>
                            <div class="d-inline" v-if="i.odometerOut != null">>>> ({{ i.odometerOut }} Km)</div>
                        </div>

                        <div class="text-caption" v-for="i in item.batteries" :key="i">
                            <div class="d-inline">
                                <strong> Bateria: {{ i.battery.tag || i.battery.name }} </strong>
                            </div>
                            <div class="d-inline" v-if="i.percentStart >= 0">({{ i.percentStart }} %)</div>
                            <div class="d-inline" v-if="i.percentStop >= 0">>>> ({{ i.percentStop }} %)</div>
                        </div>

                        <div v-if="(item.deliveryMode == 'StoreMotorcycle' || item.deliveryMode == 'StoreVehicle') && stepNow <= 6">
                            <v-layout wrap mt-2>
                                <v-flex xs6>
                                    <v-btn color="primary" dark small @click="$emit('changeBattery')" style="width: 30vw"> Trocar bateria </v-btn>
                                </v-flex>
                                <v-flex xs6>
                                    <v-btn color="primary" dark small @click="$emit('changeModal')" style="width: 30vw"> Trocar Scooter </v-btn>
                                </v-flex>
                            </v-layout>
                        </div>
                    </v-col>
                    <v-col v-else-if="item.deliveryMode == 'Bike'">
                        <strong>Trabalhando de bicicleta</strong>
                    </v-col>
                </v-row>
            </v-timeline-item>

            <v-timeline-item :color="`${getColor(3)} white--text`">
                <v-divider />

                <template v-slot:icon>
                    <span>3</span>
                </template>
                <v-row class="pt-1" v-if="item.dateIn">
                    <v-col cols="4">
                        <strong>{{ $moment(item.dateIn).format("HH:mm") }}</strong>
                        <div class="text-caption">{{ $moment(item.dateIn).format("DD/MM") }}</div>
                    </v-col>
                    <v-col>
                        <strong>Entrada</strong>
                    </v-col>
                </v-row>
                <v-row class="pt-1" v-else-if="item.store.canCheckinOutOfCoordinate !== true && (userLocation == null || distance == null)">
                    <v-col>
                        <strong>Buscando localização...</strong>
                    </v-col>
                </v-row>
                <v-row class="pt-1" v-else-if="item.store.canCheckinOutOfCoordinate !== true && distance > (item.store.limitDistance ? item.store.limitDistance : 0.85)">
                    <v-col>
                        <strong>Fora da area para fazer check-in : ( </strong>
                        <div class="text-caption">
                            Você está à <strong>{{ distance.toFixed(2) }}</strong> km da loja.
                        </div>
                        <v-btn x-small color="error" @click="cancelStore">Trocar de loja</v-btn>
                    </v-col>
                </v-row>
                <v-row class="pt-1" v-else>
                    <v-col cols="4">
                        <v-btn x-small color="success" @click="startDay">Check-in</v-btn>
                    </v-col>
                    <v-col>
                        <strong>Entrada</strong>
                    </v-col>
                </v-row>
            </v-timeline-item>

            <v-timeline-item :color="`${getColor(4)} white--text`" v-if="item.interval == null || item.interval > 0">
                <v-divider />

                <template v-slot:icon>
                    <span>4</span>
                </template>
                <v-row class="pt-1">
                    <v-col cols="4" v-if="item && item.dateInLunch">
                        <strong>{{ $moment(item.dateInLunch).format("HH:mm") }}</strong>
                        <div class="text-caption">{{ $moment(item.dateInLunch).format("DD/MM") }}</div>
                    </v-col>
                    <v-col cols="4" v-else-if="stepNow == 4">
                        <v-btn x-small color="success" v-if="stepNow == 4" @click="sendCheckin('dateInLunch')">Check-in</v-btn>
                    </v-col>
                    <v-col>
                        <strong v-if="stepNow == 4">Vamos {{ intervalBefore($moment().hour()) }}?</strong>
                        <strong v-if="stepNow > 4">Fui {{ intervalBefore($moment(item.dateInLunch).hour()) }}...</strong>
                    </v-col>
                </v-row>
            </v-timeline-item>

            <v-timeline-item :color="`${getColor(5)} white--text`" v-if="item.interval == null || item.interval > 0">
                <v-divider />

                <template v-slot:icon>
                    <span>5</span>
                </template>
                <v-row class="pt-1" v-if="stepNow == 5 && !canReturnLunch">
                    <v-col>
                        <strong>Aguarde {{ minutesToReturnLunch }} minuto(s) para voltar do {{ intervalAfter($moment().hour()) }}.</strong>
                    </v-col>
                </v-row>
                <v-row class="pt-1" v-else>
                    <v-col cols="4">
                        <strong v-if="item && item.dateOutLunch">{{ $moment(item.dateOutLunch).format("HH:mm") }}</strong>
                        <div class="text-caption" v-if="item && item.dateOutLunch">{{ $moment(item.dateOutLunch).format("DD/MM") }}</div>
                        <v-btn x-small color="success" v-if="stepNow == 5 && canReturnLunch" @click="sendCheckin('dateOutLunch')">Check-in</v-btn>
                    </v-col>
                    <v-col v-if="canReturnLunch">
                        <strong v-if="stepNow == 5">Voltando do {{ intervalAfter($moment().hour()) }}?</strong>
                        <strong v-if="stepNow > 5">Voltei do {{ intervalAfter($moment(item.dateOutLunch).hour()) }}...</strong>
                    </v-col>
                </v-row>
            </v-timeline-item>

            <v-timeline-item :color="`${getColor(6)} white--text`">
                <v-divider />

                <template v-slot:icon>
                    <span>-</span>
                </template>

                <div v-if="stepNow >= 6">
                    <v-row class="pt-1" v-if="item.dateOut">
                        <v-col cols="4">
                            <strong>{{ $moment(item.dateOut).format("HH:mm") }}</strong>
                            <div class="text-caption">{{ $moment(item.dateOut).format("DD/MM") }}</div>
                        </v-col>
                        <v-col>
                            <strong>Saída</strong>
                        </v-col>
                    </v-row>
                    <v-row class="pt-1" v-else-if="item.store.canCheckoutOutOfCoordinate == false && (userLocation == null || distance == null)">
                        <v-col>
                            <strong>Buscando localização...</strong>
                        </v-col>
                    </v-row>
                    <v-row class="pt-1" v-else-if="item.store.canCheckoutOutOfCoordinate == false && distance > (item.store.limitDistance ? item.store.limitDistance : 0.85)">
                        <v-col>
                            <strong>Fora da area para fazer check-out : ( </strong>
                            <div class="text-caption">
                                Você está à <strong>{{ distance.toFixed(2) }}</strong> km da loja.
                            </div>
                        </v-col>
                    </v-row>
                    <v-row class="pt-1" v-else>
                        <v-col cols="12">
                            <v-form ref="form" lazy-validation @submit.prevent="finish">
                                <v-text-field v-model="deliveryNumber" :label="$t('Digite o número de entregas feitas')" type="number" :rules="[rules.required]" />

                                <div v-for="(i, index) in item.modals" :key="i">
                                    <v-text-field v-if="i.odometerIn != null && itemClonned.modals[index].odometerOut == null" v-model="i.odometerOut"
                                        :label="$t('Digite o valor do km atual do painel da moto')" :hint="modalDescription(i.modal)" persistent-hint type="number" suffix="km"
                                        :rules="[rules.required, minOdometerOut(i.odometerIn), rules.odometer]" />
                                </div>

                                <div class="text-caption" v-for="(i, index) in item.batteries" :key="i">
                                    <v-text-field v-if="i.percentStart != null && itemClonned.batteries[index].percentStop == null" type="number"
                                        :label="`${$t('Porcentagem Bateria')}: ${i.battery.tag || i.battery.name}`" :hint="batteryDescription(i.battery)" persistent-hint suffix="%"
                                        v-model="i.percentStop" :rules="[rules.required, maxBatteryOut(i.percentStart), rules.range(0, 100)]" />
                                </div>

                                <v-btn x-small color="success" v-if="stepNow == 6" @click="finish">Finalizar dia de trabalho</v-btn>
                            </v-form>
                        </v-col>
                    </v-row>
                    <v-row v-if="stepNow > 6">
                        <v-col>
                            <div class="text-caption" v-if="item.deliveryNumber">Número de entregas: {{ item.deliveryNumber }}</div>
                        </v-col>
                    </v-row>
                    <v-row v-if="getTimeToReopen != null">
                        <v-col>
                            <div class="text-caption">Você poderá voltar a trabalhar em: {{ getTimeToReopen }}. Aproveite para descançar.</div>
                        </v-col>
                    </v-row>
                </div>
            </v-timeline-item>
        </v-timeline>
    </div>
</template>


<script>
import rules from "@/helpers/rules";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    watch: {
        item: {
            immediate: true,
            handler(newVal) {
                this.$nextTick(() => {
                    this.itemClonned = JSON.parse(JSON.stringify(newVal));
                });
            },
        },
    },

    data() {
        return {
            rules,
            odometerOut: null,
            deliveryNumber: null,
            minOdometerOut: (odometerIn) => (value) => Number.parseInt(value) >= Number.parseInt(odometerIn) || this.$t("Odometro de saída tem que ser maior que a entrada."),
            maxBatteryOut: (percentIn) => (value) => Number.parseInt(value) <= Number.parseInt(percentIn) || this.$t("Porcentagem da bateria tem que ser menor que na entrada."),
            timer: null,
            now: this.$moment(),
            distance: null,
            userLocation: null,
            locationError: false,
            gettingLocation: false,
            itemClonned: null,
        };
    },

    mounted() {
        this.update();
        this.timer = setInterval(this.update, 1000);
    },

    computed: {
        getTimeToReopen() {
            if (this.item && this.item.dateOut) {
                return `${20 - this.now.diff(this.item.dateOut, "minutes")} minutos`;
            } else {
                return null;
            }
        },
        minutesToReturnLunch() {
            if (this.item && this.item.dateInLunch && this.item.interval > 0) {
                return Math.round(this.item.interval - this.$moment().diff(this.item.dateInLunch, "minutes"));
            } else {
                return 0;
            }
        },
        canReturnLunch() {
            if (this.item.interval == null) {
                return true;
            } else if (this.item && this.item.dateInLunch && this.item.interval > 0) {
                return this.$moment().diff(this.item.dateInLunch, "minutes") < this.item.interval ? false : true;
            } else {
                return false;
            }
        },
        stepNow() {
            if (this.item != null)
                if (this.item.dateOut) {
                    return 7;
                } else if (this.item.dateOutLunch || (this.item.interval == 0 && this.item.dateIn)) {
                    return 6;
                } else if (this.item.dateInLunch) {
                    return 5;
                } else if (this.item.dateIn) {
                    return 4;
                } else if (this.item.deliveryMode) {
                    return 3;
                } else if (this.item.store) {
                    return 2;
                }
            return 1;
        },
    },

    methods: {
        modalDescription(modal) {
            if (modal) {
                if (modal.plate) {
                    return `Placa: ${modal.plate}`;
                } else if (modal.serialNumber) {
                    return `Número de série: ${modal.serialNumber}`;
                }
            }
            return null;
        },
        batteryDescription(battery) {
            if (battery) {
                if (battery.tag) {
                    return `Código: ${battery.tag}`;
                }
                if (battery.tag) {
                    return `Número: ${battery.name}`;
                }
            }
            return null;
        },
        intervalBefore(hour) {
            return this.isDay(hour) ? "almoçar" : "jantar";
        },
        intervalAfter(hour) {
            return this.isDay(hour) ? "almoço" : "jantar";
        },
        isDay(hour) {
            return hour >= 6 && hour <= 16;
        },
        update() {
            this.now = this.$moment();

            this.getLocation();
            if (this.userLocation != null && this.item.store) {
                let re = /\s*,\s*/;
                let storeCoordinate = this.item.store.coordinate.split(re);
                this.distance = this.calcDistance(this.userLocation[0], this.userLocation[1], storeCoordinate[0], storeCoordinate[1]);
            }
        },
        toRad(value) {
            return (value * Math.PI) / 180;
        },
        calcDistance(lon1, lat1, lon2, lat2) {
            var R = 6371; // Radius of the earth in km
            var dLat = this.toRad(lat2 - lat1); // Javascript functions in radians
            var dLon = this.toRad(lon2 - lon1);
            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c; // Distance in km
            return d;
        },
        getLocation() {
            if (!this.gettingLocation) {
                this.gettingLocation = true;
                let mainContext = this;
                navigator.geolocation.getCurrentPosition(
                    (location) => {
                        mainContext.locationError = false;
                        mainContext.userLocation = [location.coords.latitude, location.coords.longitude];
                        window.console.log(`${location.coords.latitude}, ${location.coords.longitude}`);
                        mainContext.gettingLocation = false;
                    },
                    (error) => {
                        mainContext.locationError = true;
                        window.console.log(error.message);
                        mainContext.gettingLocation = false;
                    },
                    {
                        enableHighAccuracy: true,
                        // timeout: 1000,
                        // maximumAge: 0,
                    }
                );
            }
        },
        getColor(step) {
            if (this.item == null) {
                return "grey";
            }
            return this.stepNow == step ? "red" : this.stepNow > step ? "green" : "grey";
        },
        cancelStore() {
            this.$http
                .delete(`checkin/${this.item.id}/cancelStore`)
                .then(() => {
                    this.$emit("update");
                })
                .catch(() => this.$eventHub.$emit("msgError", this.$t("Erro ao fazer checkin.")));
        },
        startDay() {
            this.$http
                .patch(`checkin/${this.item.id}/start`, { data: { coordinateIn: `${this.userLocation[0].toFixed(6)}, ${this.userLocation[1].toFixed(6)}` } })
                .then(() => {
                    this.$emit("update");
                })
                .catch((error) => this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao fazer checkin.")));
        },
        finish() {
            if (this.$refs.form.validate()) {
                let itemToFinish = {
                    coordinateOut: `${this.userLocation[0].toFixed(6)}, ${this.userLocation[1].toFixed(6)}`,
                    deliveryNumber: this.deliveryNumber,
                };
                if (this.item.batteries) {
                    itemToFinish.batteries = this.item.batteries;
                }
                if (this.item.modals) {
                    itemToFinish.modals = this.item.modals;
                }

                this.$http
                    .patch(`checkin/${this.item.id}/finish`, {
                        data: itemToFinish,
                    })
                    .then(() => {
                        this.$emit("update");
                    })
                    .catch((error) => this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao finalizar checkin.")));
            }
        },
        sendCheckin(period) {
            this.$http
                .patch(`checkin/${this.item.id}/time`, { data: { period } })
                .then(() => {
                    this.$emit("update");
                })
                .catch((error) => this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao fazer checkin.")));
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
