<template>
    <v-dialog v-model="dialog" transition="dialog-top-transition" max-width="600">
        <template v-slot:activator="{ on, attrs }">
            <v-btn style="width: 100%" class="mt-12" color="info" small v-bind="attrs" v-on="on"> Adicionar AT </v-btn>
        </template>
        <v-card>
            <v-toolbar color="primary" dark>Cadastro de AT's</v-toolbar>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
                    <v-container grid-list-xl>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field :label="$t('Digite código da AT')" v-model="codeAt" v-mask="rules.alphanumericMask" :rules="[rules.required, rules.alphanumeric]" />
                                <v-btn color="success" style="width: 100%" @click="send" small>Adicionar</v-btn>
                            </v-flex>
                            <v-flex xs12>
                                <v-alert outlined type="error" prominent border="left" v-if="ats == null" dense> Erro ao buscar listagem. </v-alert>
                                <div class="text-caption" v-for="i in ats" :key="i">
                                    <div class="d-inline">
                                        <strong> Código: {{ i.code }} </strong>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn color="error" @click="dialog = false" small>Fechar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    props: {
        checkin: {
            type: Number,
            required: true,
        },
        store: {
            type: Number,
            required: true,
        },
    },

    directives: { mask },

    data() {
        return {
            rules,
            valid: true,
            codeAt: null,
            dialog: false,
            ats: [],
        };
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
        send() {
            this.$http
                .post(`ats`, { checkin: this.checkin, store: this.store, code: this.codeAt })
                .then(() => {
                    this.getList();
                    // this.dialog = false;
                    this.codeAt = null;

                    if (this.$refs.form) {
                        this.$refs.form.reset();
                    }
                })
                .catch((error) => this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao salvar.")));
        },
        getList() {
            this.$http
                .get(`ats/byCkeckin/${this.checkin}`)
                .then((result) => {
                    if (result) {
                        this.ats = result;
                    }
                })
                .catch(() => {
                    this.ats = null;
                });
        },
    },

    mounted() {
        this.getList();
        if (this.$refs.form) {
            this.$refs.form.reset();
        }
    },
};
</script>