<template>
    <div class="text-xs-center">
        <v-bottom-sheet v-model="showInfo">
            <v-footer dark padless>
                <v-card class="flex" flat tile>
                    <v-card-title class="primary">
                        <strong class="subheading">R2PP</strong>

                        <v-spacer></v-spacer>

                        <v-btn-toggle v-model="$i18n.locale" mandatory background-color="primary">
                            <v-btn value="pt" color="primary" @click="changeLang('pt')">
                                <v-avatar size="30px">
                                    <img :src="require('@/assets/images/pt.png')" alt="Português" />
                                </v-avatar>
                            </v-btn>
                            <v-btn value="en" color="primary" @click="changeLang('en')">
                                <v-avatar size="30px">
                                    <img :src="require('@/assets/images/en.png')" alt="English" />
                                </v-avatar>
                            </v-btn>
                        </v-btn-toggle>

                        <!-- <v-btn class="mx-4" dark icon href="https://www.instagram.com/r2ppbrasil/" target="_blank">
                            <v-icon size="24px">fab fa-instagram</v-icon>
                        </v-btn> -->
                    </v-card-title>
                    <v-card-text class="primary">
                        <div>São Paulo - SP</div>
                        <!-- <div><v-icon size="20px" left>call</v-icon>+55 (11) 9xxx-xxxx</div> -->
                        <div><v-icon size="20px" left>email</v-icon>contato@r2pp.com.br</div>
                        <router-link to="/privacy" tag="v-btn">
                            <center><v-btn text x-small>Política de Privacidade</v-btn></center>
                        </router-link>
                    </v-card-text>
                    <v-card-text class="py-2 white--text text-center">
                        <v-btn text href="https://sdeveloper.io" target="_blank">
                            &copy;{{ $moment().year() }} —
                            <strong>sdeveloper.io</strong>
                        </v-btn>
                        <Version />
                    </v-card-text>
                </v-card>
            </v-footer>
        </v-bottom-sheet>
    </div>
</template>

<script>
import Version from "@/components/core/Version";
export default {
    name: "Info",
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    components: {
        Version,
    },

    data() {
        return { langs: ["pt", "en"] };
    },

    methods: {
        changeLang(lang) {
            this.$i18n.locale = lang;
            this.$store.commit("SET_LANG", lang);
        },
        reload() {
            document.location.reload(true);
        },
    },

    computed: {
        showInfo: {
            get: function () {
                return this.show;
            },
            set: function (value) {
                if (!value) {
                    this.$emit("onClose");
                }
            },
        },
    },
};
</script>