<template>
    <v-container grid-list text-center>
        <v-card dark color="primary" v-if="stores == null">
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="headline">Buscando lojas...</v-card-title>
                </div>
                <v-avatar class="ma-3" size="50" tile>
                    <v-progress-circular size="50" width="10" color="white" indeterminate/>
                </v-avatar>
            </div>
        </v-card>
        <v-card dark color="primary" v-else-if="stores.length == 0">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>Não existe nenhum plano de trabalho para você.</v-card-title>
            </div>
        </v-card>
        <v-layout wrap v-else>
            <v-flex xs12 class="ma-2">
                <p class="font-weight-black">Selecione abaixo qual a loja que você ira trabalhar hoje:</p>
            </v-flex>
            <v-flex xs12 v-for="item of stores" :key="item.id" class="ma-2">
                <v-btn color="success" @click="$emit('onSelect', item)" class="mx-auto" width="100%" :disabled="!can(item)">
                    {{ item.name }} ( {{ $moment.utc(item.date).format("DD/MM/YYYY") }} {{ item.start }} ~ {{ item.stop }})
                </v-btn>
                <small v-if="!can(item)">{{ now.format("DD/MM/YY HH:mm") }} >>> {{ dateTime.setTimeInDate(item.date, item.start).format("DD/MM/YY HH:mm") }}</small>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import dateTime from "@/helpers/DateTime";

export default {
    props: {
        now: {
            required: true,
        },
    },

    data() {
        return {
            dateTime,
            stores: null,
        };
    },

    mounted() {
        this.$http
            .get(`checkin/stores`)
            .then((result) => {
                if (result) {
                    this.stores = result;
                }
            })
            .catch(() => {
                this.stores = null;
            });
    },

    computed: {
        can() {
            return (item) =>
                this.now.isAfter(this.dateTime.setTimeInDate(item.date, item.start).subtract({ hours: 1 })) &&
                this.now.isBefore(this.dateTime.setTimeInDate(item.date, item.start).add({ hours: 1 }));
        },
    },
};
</script>
