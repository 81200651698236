<template>
    <v-container grid-list text-center>
        <v-form v-model="valid" ref="form" class="ma-8" lazy-validation @submit.prevent="makeCheckin">
            <v-layout wrap>
                <v-flex xs12 class="ma-2">
                    <p class="font-weight-black">Preencha as informações abaixo:</p>
                </v-flex>

                <v-flex xs12 class="ma-2">
                    <v-text-field v-model="odometerIn" :label="$t('Digite o valor do km atual do painel')" type="number" suffix="km" :rules="[rules.required, rules.odometer]" />
                </v-flex>

                <v-flex xs12 class="ma-2">
                    <v-btn @click="makeCheckin" :disabled="!valid" color="green white--text" style="width: 100%"> <v-icon left> play_arrow </v-icon> Próximo passo </v-btn>
                </v-flex>
            </v-layout>
        </v-form>
    </v-container>
</template>

<script>
import rules from "@/helpers/rules";
export default {
    data() {
        return {
            valid: true,
            rules,
            odometerIn: null,
        };
    },

    methods: {
        makeCheckin() {
            if (this.$refs.form.validate()) {
                this.$emit("onSelect", { modals: { odometerIn: this.odometerIn } });
            }
        },
    },
};
</script>
