import Vue from 'vue'
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import VueI18n from 'vue-i18n'
import dictionary from '@/plugins/i18n'
import api from '@/helpers/api'
import store from '@/helpers/store'
import router from '@/helpers/router'
import eventHub from './eventHub'

import './registerServiceWorker'

const moment = require('moment')

Vue.prototype.$eventHub = eventHub;

Vue.prototype.$http = api;

Vue.config.productionTip = false;

Vue.use(VueI18n);

const i18n = new VueI18n(dictionary)

if (store.getters.getLang == "en") {
  require('moment/locale/en-ca')
} else if (store.getters.getLang == "es") {
  require('moment/locale/es')
} else {
  require('moment/locale/pt')
}

Vue.use(require('vue-moment'), {
  moment
});

new Vue({
  i18n,
  vuetify,
  render: h => h(App),
  router,
  store,
}).$mount('#app')
