<template>
    <v-container grid-list text-center>
        <v-card dark color="primary" v-if="deliveryModes == null">
            <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                    <v-card-title class="headline">Buscando opções...</v-card-title>
                </div>
                <v-avatar class="ma-3" size="50" tile>
                    <v-progress-circular size="50" width="10" color="white" indeterminate/>
                </v-avatar>
            </div>
        </v-card>
        <v-card dark color="primary" v-else-if="deliveryModes.length == 0">
            <div class="d-flex flex-no-wrap justify-space-between">
                <v-card-title>Não existe nenhuma metodo de trabalho liberado para você.</v-card-title>
            </div>
        </v-card>
        <v-layout wrap v-else>
            <v-flex xs12 ma-2>
                <p class="font-weight-black">Qual a forma de trabalho?</p>
            </v-flex>

            <v-flex xs12 ma-2 v-if="deliveryModes.hasMotorcycle">
                <v-btn color="success" @click="$emit('onSelect', 'OwnMotorcycle')">Moto própria</v-btn>
            </v-flex>
            <v-flex xs12 ma-2 mb-12 v-if="deliveryModes.isDeliveryByMotorcycle">
                <v-btn color="success" @click="$emit('onSelect', 'StoreMotorcycle')">Scooter elétrica</v-btn>
            </v-flex>
            <v-flex xs12 ma-2 v-if="deliveryModes.hasCar">
                <v-btn color="success" @click="$emit('onSelect', 'OwnVehicle')">Veículo próprio</v-btn>
            </v-flex>
            <v-flex xs12 ma-2 mb-12 v-if="deliveryModes.isDeliveryByCar">
                <v-btn color="success" @click="$emit('onSelect', 'StoreVehicle')">Veículo Empresa</v-btn>
            </v-flex>
            <v-flex xs12 ma-2 v-if="deliveryModes.isDeliveryByBike">
                <v-btn color="success" @click="$emit('onSelect', 'Bike')">Bicicleta</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            deliveryModes: null,
        };
    },

    mounted() {
        this.$http
            .get(`checkin/deliveryModes`)
            .then((result) => {
                if (result) {
                    this.deliveryModes = result;
                }
            })
            .catch(() => {
                this.deliveryModes = null;
            });
    },
};
</script>
