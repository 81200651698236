<template>
    <v-container fluid fill-height class="loginOverlay">
        <v-layout flex align-center justify-center>
            <v-flex xs12 sm8 md6 xl4 elevation-6>
                <v-toolbar class="primary">
                    <v-toolbar-title class="white--text">
                        <h4>R2PP</h4>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card>
                    <v-card-text class="pt-4">
                        <LoginComponent @login="login"></LoginComponent>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import LoginComponent from "@/components/form/Login";
export default {
    name: "Login",
    components: {
        LoginComponent,
    },
    computed: {
        ...mapGetters(["verifyRole"]),
    },
    methods: {
        login() {
            if (this.$store.getters.isLogged && this.verifyRole("EXPERT")) {
                this.$router.replace({ path: "/daily" });
            } else if (this.$store.getters.isLogged && this.verifyRole("PRE_EXPERT")) {
                this.$router.replace({ path: "/pendingExpert" });
            } else if (this.$store.getters.isLogged && this.verifyRole("PARTNER")) {
                this.$router.replace({ path: "/dashboard-partner" });
            } else {
                this.$router.replace({ path: this.$store.state.routeBeforeLogin });
            }
            // window.location.reload(true);
        },
    },
};
</script>