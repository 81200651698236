<template>
    <div color="grey lighten-3">
        <v-card color="grey lighten-3" style="height: 100vh">
            <v-card flat class="rounded-xl">
                <v-container style="background-color: #f1cb30" class="mx-auto rounded-xl white--text" fill-height fluid>
                    <v-container class="fill-height mx-8">
                        <v-row align="center">
                            <v-col cols="12" sm="2">
                                <v-row justify="center">
                                    <img height="140" class="d-none d-sm-flex" :src="require('@/assets/images/logo-white.svg')" />
                                    <img height="70" class="d-flex d-sm-none mb-2" :src="require('@/assets/images/logo-white.svg')" />
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="10" align-self="auto">
                                <v-row justify="center" justify-sm="end" class="mb-6 mr-sm-4">
                                    <strong class="text-h3 font-weight-regular">{{ $moment().format("DD/MM/Y") }}</strong>
                                </v-row>
                                <v-row justify="center" justify-sm="end">
                                    <p class="ma-0 pa-0 font-weight-light" v-if="this.item && this.item.id">
                                        {{ this.item.id }}
                                    </p>
                                </v-row>
                                <v-row justify="center" justify-sm="end">
                                    <p class="ma-0 pa-0" v-if="item && item.expertName">
                                        {{ item.expertName }}
                                    </p>
                                    <p class="ma-0 pa-0" v-else>
                                        {{ this.$store.state.login.email }}
                                    </p>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-container>
            </v-card>

            <v-card-text class="py-0" v-if="showChangeBattery" height="100vh">
                <ChangeBattery :item="item" @onClose="() => { this.showChangeBattery = false; this.update(); }" />
            </v-card-text>
            <v-card-text class="py-0" v-else-if="showChangeModal">
                <ChangeEletricalModal :item="item" @onClose="() => { this.showChangeModal = false; this.update(); }" />
            </v-card-text>
            <v-card-text class="py-0" v-else-if="!error">
                <ChooseStore v-if="!hasSelectedStore" :now="now" @onSelect="chosenStore" />
                <ChooseDeliveryMode v-else-if="!hasSelectedDeliveryMode" @onSelect="chosenDeliveryMode" />
                <ChooseOwnModal v-else-if="needsSelecteOwnModal" @onSelect="chosenOwnModal" />
                <ChooseEletricalModal v-else-if="needsSelecteEletricalModal" :store="getStore" @onSelect="chosenEletricalModal" @onCancel="reload" />
                <DailyTimeline v-else @update="getDaily" @changeBattery="showChangeBattery = true" @changeModal="showChangeModal = true" :item="item" />
                <AT :checkin="item.id" :store="getStore" v-if="item != null && item.store && item.store.canAddAT" />
            </v-card-text>
            <v-card-text class="py-0" v-else>
                <v-alert outlined type="error" prominent border="left" class="ma-4">
                    O seu cadastro parece estar incompleto. <br /><br />
                    Entre em contato para verificar se existe algum dado pendente no seu cadastro.
                </v-alert>
            </v-card-text>

            <v-card-text class="py-0" v-if="showChangeBattery == false && showChangeModal == false && pendings && pendings.length > 0">
                <v-alert text color="error">
                    <h3 class="text-h7">
                        <v-badge color="error" :content="pendings.length"> Pendências </v-badge>
                    </h3>
                    <div class="mt-2">
                        Você confirma que no dia {{ $moment(lastPending.dateIn).format("DD/MM/YYYY") }} das {{ $moment(lastPending.dateIn).format("HH:mm") }} às
                        {{ $moment(lastPending.dateOut).format("HH:mm") }} você fez {{ lastPending.deliveryNumber }} entrega(s)?
                    </div>

                    <v-divider class="my-4 info" style="opacity: 0.22" />

                    <v-btn color="success" small @click="confirm(lastPending.id)" v-if="!showEdit"> Confirmar </v-btn>
                    <v-btn color="error" class="ml-4" small @click="showEdit = true" v-if="!showEdit"> Modificar </v-btn>
                    <v-text-field v-model="deliveryNumber" :label="$t('Digite o número de entregas feitas')" type="number" v-if="showEdit">
                        <template v-slot:append-outer>
                            <v-btn color="success" small @click="changeDeliveryNumber(lastPending.id)"> Confirmar </v-btn>
                        </template>
                    </v-text-field>
                </v-alert>
            </v-card-text>
        </v-card>
        <Pending :item="item" />
    </div>
</template>

<script>
import dateTime from "@/helpers/DateTime";
import ChooseStore from "./ChooseStore";
import ChooseDeliveryMode from "./ChooseDeliveryMode";
import ChooseOwnModal from "./ChooseOwnModal";
import ChooseEletricalModal from "./ChooseEletricalModal";
import DailyTimeline from "./DailyTimeline";
import ChangeBattery from "./ChangeBattery";
import ChangeEletricalModal from "./ChangeEletricalModal";
import AT from "./AT";
import Pending from "./Pending";

const ITEM_CLEAN = { store: null, deliveryMode: null, dateInPlanned: null, dateOutPlanned: null, interval: null };

export default {
    components: {
        ChooseStore,
        ChooseDeliveryMode,
        ChooseOwnModal,
        ChooseEletricalModal,
        DailyTimeline,
        ChangeBattery,
        ChangeEletricalModal,
        AT,
        Pending,
    },

    data() {
        return {
            dateTime,
            timer: null,
            item: null,
            now: null,
            error: false,
            pendings: [],
            showEdit: false,
            deliveryNumber: 0,
            tmpItem: ITEM_CLEAN,
            showChangeBattery: false,
            showChangeModal: false,
        };
    },

    mounted() {
        this.update();
        this.getPending();
        this.timer = setInterval(this.update, 60 * 1000);
    },

    computed: {
        getStore() {
            return this.item != null && this.item.store ? this.item.store.id : this.tmpItem.store;
        },
        getItem() {
            return this.item != null ? this.item : this.tmpItem;
        },
        hasSelectedStore() {
            return (this.item != null && this.item.store) || this.tmpItem.store;
        },
        hasSelectedDeliveryMode() {
            return (this.item != null && this.item.deliveryMode) || this.tmpItem.deliveryMode;
        },
        needsSelecteOwnModal() {
            if (this.item != null && (this.item.deliveryMode == "OwnMotorcycle" || this.item.deliveryMode == "OwnVehicle")) {
                return this.item != null && this.item.modals == null;
            } else if (this.tmpItem.deliveryMode != null && (this.tmpItem.deliveryMode == "OwnMotorcycle" || this.tmpItem.deliveryMode == "OwnVehicle")) {
                return true;
            }
            return false;
        },
        needsSelecteEletricalModal() {
            if (this.item != null && (this.item.deliveryMode == "StoreMotorcycle" || this.item.deliveryMode == "StoreVehicle")) {
                return this.item != null && this.item.modals == null;
            } else if (this.tmpItem.deliveryMode != null && (this.tmpItem.deliveryMode == "StoreMotorcycle" || this.tmpItem.deliveryMode == "StoreVehicle")) {
                return true;
            }
            return false;
        },
        lastPending() {
            return this.pendings && this.pendings.length > 0 ? this.pendings[this.pendings.length - 1] : null;
        },
    },

    methods: {
        chosenStore(item) {
            this.tmpItem.dateInPlanned = this.dateTime.setTimeInDate(item.date, item.start);
            this.tmpItem.dateOutPlanned = this.dateTime.setTimeInDate(item.date, item.stop);
            this.tmpItem.interval = item.interval;

            this.tmpItem.store = item.id;
        },
        chosenDeliveryMode(deliveryMode) {
            this.tmpItem.deliveryMode = deliveryMode;
            if (deliveryMode == "Bike") {
                this.createCheckin({});
            }
        },
        chosenOwnModal(data) {
            if (data.modals && data.modals.odometerIn) {
                this.createCheckin({
                    modals: data.modals,
                });
            }
        },
        chosenEletricalModal(data) {
            if (data.modals && data.modals.odometerIn) {
                this.createCheckin({
                    ...data,
                    modals: data.modals,
                });
            }
        },
        createCheckin(extraParameters) {
            if (this.tmpItem.store && this.tmpItem.deliveryMode) {
                if (this.item != null && this.item.id) {
                    this.$eventHub.$emit("msgError", this.$t("Você não deveria estar nessa etapa. Entre em contato com o suporte"));
                } else {
                    this.$http
                        .post("checkin/create", { ...this.tmpItem, ...extraParameters })
                        .then(() => {
                            this.tmpItem = ITEM_CLEAN;

                            this.getDaily();
                        })
                        .catch((error) => this.$eventHub.$emit("msgError", error ? error : this.$t("Erro ao criar.")));
                }
            } else {
                this.$eventHub.$emit("msgError", this.$t("Erro ao detectar loja."));
            }
        },
        getPending() {
            this.$http
                .get(`daily/pending`)
                .then((result) => {
                    this.pendings = result;
                    this.showEdit = false;
                    this.deliveryNumber = this.lastPending.deliveryNumber ? this.lastPending.deliveryNumber : 0;
                })
                .catch(() => {
                    this.pendings = [];
                    this.showEdit = false;
                });
        },
        confirm(id) {
            this.$http
                .patch(`daily/${id}/confirm`)
                .then(() => {
                    this.getPending();
                })
                .catch(() => this.$eventHub.$emit("msgError", this.$t("Erro ao enviar confirmação.")));
        },
        changeDeliveryNumber(id) {
            this.$http
                .patch(`daily/${id}/changeDeliveryNumber`, { data: { deliveryNumber: this.deliveryNumber } })
                .then(() => {
                    this.getPending();
                })
                .catch(() => this.$eventHub.$emit("msgError", this.$t("Erro ao enviar confirmação.")));
        },
        getDaily() {
            this.now = this.$moment.utc().subtract({ hours: 3 });
            this.$http
                .get(`daily`)
                .then((result) => {
                    this.item = result;
                    this.error = false;
                })
                .catch(() => {
                    this.item = null;
                    this.error = true;
                });
        },
        update() {
            this.getDaily();
        },
        reload() {
            document.location.reload();
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
