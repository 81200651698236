<template>
    <v-dialog v-model="dialog" transition="dialog-top-transition" fullscreen>
        <v-card class="fill-height" fluid>
            <v-toolbar color="primary" dark>Pendências de cadastro</v-toolbar>
            <v-card-text class="ma-0 pa-0">
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
                    <v-container grid-list-xl>
                        <v-alert border="left" color="primary" v-if="item && item.needPhone" outlined elevation="2">
                            <div class="text-h6">
                                Cadastro de telefone
                            </div>
                            <v-layout wrap>
                                <v-flex xs12>
                                    <v-text-field type="tel" v-model="phone" :rules="[rules.required, rules.cellphone]" :label="$t('Celular')"
                                        v-mask="['(##) ####-####', '(##) #####-####']" required />
                                </v-flex>
                            </v-layout>
                        </v-alert>
                        <Pix v-if="item && item.needPix" :item="pix" :email="$store.state.login.email" color="primary" />
                    </v-container>
                </v-form>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
                <v-btn color="success" @click="send" :disabled="!valid">Salvar</v-btn>
                <v-btn color="error" @click="dialog = false">Fechar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";
import Pix from "@/components/shared/Pix";

export default {
    components: { Pix },

    directives: { mask },

    props: {
        item: {
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            dialog: false,
            phone: "",
            pix: {
                pixType: "",
                pixKey: "",
                pixBank: ""
            }
        };
    },

    watch: {
        item: {
            immediate: true,
            handler() {
                if (this.dialog == false) {
                    if (this.item && (this.item.needPhone || this.item.needPix)) {
                        this.dialog = true;
                    }
                    if (this.$refs.form) {
                        this.$refs.form.reset();
                    }
                }
            },
        },
    },

    methods: {
        send() {
            if (this.$refs.form.validate()) {
                let data = {};
                if (this.item.needPhone) {
                    data.phone = this.phone;
                }
                if (this.item.needPix) {
                    data = { ...data, ...this.pix }
                }
                this.$http
                    .patch(`/experts/${this.item.expertId}/pending`, { data })
                    .then(() => {
                        this.dialog = false;
                    })
                    .catch((error) => this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao salvar.")));
            }
        },
    },
};
</script>